import React from "react";

import Page from "../components/Page";
import SEO from "../components/SEO";
import { Contact } from "../components/Contact";

import { main } from "../helpers/navigation";

const ContactsPage = () => {
  return (
    <Page
      page="Contacts"
      pageName="Контакти"
      title="Надіслати повідомлення"
      parameters={main}
    >
      <SEO title="Контакти" />

      <Contact />
    </Page>
  );
};

export default ContactsPage;
